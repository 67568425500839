/** Libs */
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

/** Tokens */
import { neutral } from '../../assets/tokens/colors'
import { border } from '../../assets/tokens/borders'

/** Services */
import { ptBrNumberFormatter } from '../../services/helper'
import { PDFWrapper } from '../NotPrintable/style'

interface TDaily {
  translationsData: {
    day: string
    dayNumber: string
  }[]
}
interface IAccumulative {
  translationsData: {
    day: string
  }[]
}

interface IMonthly {
  translationsData: {
    forMonth: string
  }[]
}

/** Função que retorna gráficos de tradução diária */
export const Daily: React.FC<TDaily & { translation: Function }> = ({
  translationsData,
  translation,
}) => {
  return (
    <PDFWrapper>
      <ResponsiveContainer width="100%" height={448}>
        <BarChart
          data={translationsData}
          margin={{
            top: 40,
            right: 20,
            left: 0,
            bottom: 50,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="day"
            tickLine={{ stroke: '#656565', strokeWidth: 2 }}
            tickSize={15}
            padding={{ left: 20, right: 50 }}
            axisLine={{ stroke: '#656565', strokeWidth: 2 }}
            angle={45}
            textAnchor="end"
            dx={30}
            dy={45}
          />
          <YAxis
            tickFormatter={(value) =>
              ptBrNumberFormatter(value, true) as string
            }
            tick={{
              fontSize: 12,
              fill: '#292929',
              fontFamily: 'Lato',
              fontWeight: 400,
            }}
          />
          <Tooltip
            formatter={(value) => ptBrNumberFormatter(value)}
            contentStyle={{
              border: `1px solid ${neutral.color.high.light}`,
              borderRadius: border.radius.md,
            }}
          />
          <Legend />
          <Bar
            dataKey={translation('translatedWordsTitle')}
            fill={'#C64F01'}
            strokeWidth={2}
            legendType="none"
          />
        </BarChart>
      </ResponsiveContainer>
    </PDFWrapper>
  )
}

/** Função que retorna gráficos de tradução acumulativa */
export const Acumulative: React.FC<
  IAccumulative & { translation: Function }
> = ({ translationsData, translation }) => (
  <PDFWrapper>
    <ResponsiveContainer width="100%" height={448}>
      <LineChart
        data={translationsData}
        margin={{
          top: 40,
          right: 20,
          left: 0,
          bottom: 50,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          interval={
            translationsData.length > 600
              ? 183
              : translationsData.length > 140
              ? 50
              : 5
          }
          dataKey="day"
          tickLine={{ stroke: '#656565', strokeWidth: 2 }}
          tickSize={15}
          padding={{ left: 20, right: 50 }}
          axisLine={{ stroke: '#656565', strokeWidth: 2 }}
          angle={45}
          textAnchor="end"
          dx={30}
          dy={45}
        />
        <YAxis
          tickFormatter={(value) => ptBrNumberFormatter(value, true) as string}
          tick={{
            fontSize: 12,
            fill: '#292929',
            fontFamily: 'Lato',
            fontWeight: 400,
          }}
        />
        <Tooltip formatter={(value) => ptBrNumberFormatter(value)} />
        <Legend />
        <Line
          dataKey={translation('translatedWordsTitle')}
          stroke={'#C64F01'}
          strokeWidth={2}
          activeDot={{ r: 8 }}
          legendType="none"
          dot={!(translationsData.length >= 365)}
        />
      </LineChart>
    </ResponsiveContainer>
  </PDFWrapper>
)

/* Função que retorna gráfico de tradução por mês */
export const Monthly: React.FC<
  IMonthly & {
    translation: Function
  }
> = ({ translationsData, translation }) => {
  return (
    <PDFWrapper>
      <ResponsiveContainer width="100%" height={448}>
        <BarChart
          data={translationsData}
          margin={{
            top: 40,
            right: 20,
            left: 0,
            bottom: 50,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="forMonth"
            tick={{
              fontSize: 12,
              fill: '#292929',
              fontFamily: 'Lato',
              fontWeight: 400,
            }}
            tickLine={{ stroke: '#656565', strokeWidth: 2 }}
            tickSize={15}
            padding={{ left: 20, right: 50 }}
            axisLine={{ stroke: '#656565', strokeWidth: 2 }}
            angle={45}
            textAnchor="end"
            dx={20}
            dy={50}
          />
          <YAxis
            tickFormatter={(value) =>
              ptBrNumberFormatter(value, true) as string
            }
            tick={{
              fontSize: 12,
              fill: '#292929',
              fontFamily: 'Lato',
              fontWeight: 400,
            }}
          />
          <Tooltip
            formatter={(value) => ptBrNumberFormatter(value)}
            contentStyle={{
              border: `1px solid ${neutral.color.high.light}`,
              borderRadius: border.radius.md,
            }}
          />
          <Legend />
          <Bar
            dataKey={translation('translatedWordsTitle')}
            fill={'#C64F01'}
            width={15}
            legendType="none"
          />
        </BarChart>
      </ResponsiveContainer>
    </PDFWrapper>
  )
}
