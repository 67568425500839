/** Libs */
import { container } from '../../inversify.config'

/** Use Case */
import FilteredInsightReportUseCase from '../../domain/use-cases/FilteredInsightReportUseCase'

/** Types */
import TYPES from '../../core/Types'

export default function useFilteredInsightReportUseCase() {
  return container.get(
    TYPES.FilteredInsightReportUseCase
  ) as FilteredInsightReportUseCase
}
