// Interfaces
import { ILoadingWrapper } from '../../services/interfaces'

// Components
import LoadingAnimated from '../../components/LoadingAnimated'
import Container from '../../components/Container'

const LoadingComponent: React.FC = () => (
  <>
    <Container>
      <LoadingAnimated />
    </Container>
  </>
)

const LoadingWrapper: React.FC<ILoadingWrapper> = ({ loading, children }) => {
  return loading ? <LoadingComponent /> : <>{children}</>
}

export default LoadingWrapper
