/** Libs */
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

/** Types */
import { Language } from '../services/interfaces'

/** Utils */
import { getOralLanguage } from '../services/utils'

/** Translations */
import translationsReport from '../pages/ClientDashboard/ClientDashboard.json'
import translationsTopAddons from '../components/TopAddons/TopAddons.json'
import translationsLanguageSwitcher from '../components/LanguageSwitcher/LanguageSwitcher.json'
import translationsHeader from '../components/Header/Header.json'
import translationsHeaderForm from '../components/HeaderForm/HeaderForm.json'
import translationFeedbackCard from '../components/FeedbackCard/FeedbackCard.json'
import translationFilter from '../components/Filter/Filter.json'
import translationsTopContainer from '../components/TopContainer/TopContainer.json'
import translationsOverview from '../components/Overview/Overview.json'
import translationChart from '../components/Chart/Chart.json'
import translationChartUsers from '../components/ChartUsers/ChartUsers.json'
import translationsUserTable from '../components/UserTable/UserTable.json'
import translationsAchievement from '../components/Achievement/Achievement.json'
import translationsFooter from '../components/Footer/Footer.json'
import translationsLoadingAnimated from '../components/LoadingAnimated/LoadingAnimated.json'
import translationsInvalidToken from '../components/InvalidToken/InvalidToken.json'
import translationsDataSection from '../components/DataSection/DataSection.json'
import translationsMetadata from './Metadata.json'
import authForms from '../components/AuthForms/AuthForms.json'
import menu from '../components/Menu/Menu.json'
import translationsYCalendar from '../components/YCalendar/YCalendar.json'
import table from '../components/Table/Table.json'

i18n.use(initReactI18next).init({
  resources: {
    en: {
      Report: translationsReport.en,
      TopAddons: translationsTopAddons.en,
      LanguageSwitcher: translationsLanguageSwitcher.en,
      Header: translationsHeader.en,
      HeaderForm: translationsHeaderForm.en,
      FeedbackCard: translationFeedbackCard.en,
      Filter: translationFilter.en,
      TopContainer: translationsTopContainer.en,
      UserTable: translationsUserTable.en,
      Chart: translationChart.en,
      ChartUsers: translationChartUsers.en,
      Overview: translationsOverview.en,
      Achievement: translationsAchievement.en,
      Footer: translationsFooter.en,
      LoadingAnimated: translationsLoadingAnimated.en,
      InvalidToken: translationsInvalidToken.en,
      DataSection: translationsDataSection.en,
      Metadata: translationsMetadata.en,
      AuthForms: authForms.en,
      Menu: menu.en,
      YCalendar: translationsYCalendar.en,
      Table: table.en,
    },
    pt: {
      Report: translationsReport.pt,
      TopAddons: translationsTopAddons.pt,
      LanguageSwitcher: translationsLanguageSwitcher.pt,
      Header: translationsHeader.pt,
      HeaderForm: translationsHeaderForm.pt,
      FeedbackCard: translationFeedbackCard.pt,
      Filter: translationFilter.pt,
      TopContainer: translationsTopContainer.pt,
      UserTable: translationsUserTable.pt,
      Overview: translationsOverview.pt,
      Chart: translationChart.pt,
      ChartUsers: translationChartUsers.pt,
      Achievement: translationsAchievement.pt,
      Footer: translationsFooter.pt,
      LoadingAnimated: translationsLoadingAnimated.pt,
      InvalidToken: translationsInvalidToken.pt,
      DataSection: translationsDataSection.pt,
      Metadata: translationsMetadata.pt,
      AuthForms: authForms.pt,
      Menu: menu.pt,
      YCalendar: translationsYCalendar.pt,
      Table: table.pt,
    },
  },
  lng: 'pt',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
})

export const setLanguage = async (language: Language) => {
  const oralLanguage = getOralLanguage(language)
  await i18n.changeLanguage(oralLanguage)

  document.title = i18n.t('title', { ns: 'Metadata' })
}

export default i18n
