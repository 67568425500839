// Libs
import { useEffect } from 'react'

// Hooks
import useAuth from '../../hooks/useAuth'

// Services
import * as Analytics from '../../services/analytics'

// Components
import AuthForms from '../../components/AuthForms'
import Container from '../../components/Container'
import AnimatedLoading from '../../components/LoadingAnimated'

export default function Auth() {
  const { signIn, authState } = useAuth()

  const hasFormErrors = authState.type === 'error'

  useEffect(() => {
    if (authState.type === 'success') {
      Analytics.loginEvent()
      window.location.href = '/domain-list'
    } else if (authState.type === 'error') {
      Analytics.invalidLoginEvent()
    }
  }, [authState])

  if (authState.type === 'loading' || authState.type === 'success') {
    return (
      <Container>
        <AnimatedLoading />
      </Container>
    )
  }

  return (
    <Container>
      <AuthForms.SignIn onSubmit={signIn} error={hasFormErrors} />
    </Container>
  )
}
