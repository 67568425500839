// Libs
import { Container } from 'inversify'

// Core
import TYPES from './core/Types'

// Apis
import Config from './apis/Config'
import FirebaseApi from './apis/FirebaseApi'
import DashboardApi from './apis/DashboardApi'

// Repositories
import UserInfoRepository from './repositories/UserInfoRepository'
import UserRepository from './repositories/UserRepository'
import ReportRepository from './repositories/ReportRepository'
import AverageReportRepository from './repositories/AverageReportRepository'

// Infra
import CreateUserPasswordUseCase from './domain/use-cases/CreateUserPasswordUseCase'
import DecodeAuthTokenUseCase from './domain/use-cases/DecodeAuthTokenUseCase'
import FilteredInsightReportUseCase from './domain/use-cases/FilteredInsightReportUseCase'

const container = new Container({
  defaultScope: 'Singleton',
})

container.bind(Config).toSelf()

container.bind(FirebaseApi).toSelf()
container.bind(DashboardApi).toSelf()

container.bind(UserRepository).toSelf()
container.bind(ReportRepository).toSelf()
container.bind(UserInfoRepository).toSelf()
container.bind(AverageReportRepository).toSelf()

container.bind(TYPES.CreateUserPasswordUseCase).to(CreateUserPasswordUseCase)
container.bind(TYPES.DecodeAuthTokenUseCase).to(DecodeAuthTokenUseCase)
container
  .bind(TYPES.FilteredInsightReportUseCase)
  .to(FilteredInsightReportUseCase)

export { container }
