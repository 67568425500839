/** Libs */
import React from 'react'

/** Interfaces */
import { IPeriod, IFilterProperties } from '../services/interfaces'

/** Hooks */
import useFilteredInsightViewModel from './view-models/useFilteredInsightsViewModel'

interface IUseRegionExpansion {
  token: string
  period: IPeriod
}

const useRegionExpansion = ({ token, period }: IUseRegionExpansion) => {
  const { filteredInsight, state } = useFilteredInsightViewModel()

  const [currentPageRegion, setCurrentPageRegion] = React.useState(1)
  const [isModalOpenRegion, setIsModalOpennRegion] = React.useState(false)

  /** Gerencia o modal e limpa os estados ao fechar */
  const toggleModalRegion = () => {
    if (isModalOpenRegion) {
      setCurrentPageRegion(1)
    }
    setIsModalOpennRegion(!isModalOpenRegion)
  }

  const searchNewRegionList = (filterProperties: IFilterProperties): void => {
    const { limit, currentPage, filterContent } = filterProperties

    const offset = limit * ((currentPage || 1) - 1)

    setCurrentPageRegion(currentPage || 1)

    filteredInsight(token, period, 'region', limit || 10, offset, filterContent)
  }

  const expansionRegion =
    state.region?.type === 'success' ? state.region.report : []

  return {
    isModalOpenRegion,
    currentPageRegion,
    expansionRegion,
    dataExpansionStateRegion: state.region?.type,
    toggleModalRegion,
    searchNewRegionList,
  }
}

export default useRegionExpansion
