/** Libs */
import React from 'react'
import { YIcon } from '@hand-talk/yotta-components'
import { useTranslation } from 'react-i18next'

/** Styles */
import {
  ContainerNumber,
  ContainerPagination,
  PageNumber,
  YButtonCustom,
} from './styles'

interface IPagination {
  currentPage: number
  totalPages: number
  onPageChange: (page: number) => void
}

const Pagination: React.FC<IPagination> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const { t: Table } = useTranslation('Table')

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1)
    }
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1)
    }
  }

  const renderPageNumber = (pageNumber: number) => (
    <PageNumber
      key={pageNumber}
      isActive={currentPage === pageNumber}
      onClick={() => {
        if (pageNumber !== currentPage) {
          onPageChange(pageNumber)
        }
      }}
      aria-current={currentPage === pageNumber ? 'page' : undefined}
      aria-label={
        currentPage === pageNumber
          ? Table('currentAriaLabel')
          : Table('pageNumberAriaLabel', { page: pageNumber })
      }
      title={
        currentPage === pageNumber
          ? Table('currentAriaLabel')
          : Table('pageNumberAriaLabel', { page: pageNumber })
      }
      style={{
        cursor: pageNumber === currentPage ? 'default' : 'pointer',
      }}
    >
      {pageNumber}
    </PageNumber>
  )
  return (
    <ContainerPagination
      role="navigation"
      aria-label={Table('paginationAriaLabel', { currentPage, totalPages })}
    >
      <YButtonCustom
        onClick={handlePreviousPage}
        iconPosition="left"
        variation="text"
        icon={<YIcon name="arrowLeft" />}
        size="fitContent"
        aria-label={Table('previousPageAriaLabel', { page: currentPage - 1 })}
        title={Table('previousPageAriaLabel', { page: currentPage - 1 })}
        disabled={currentPage === 1 || totalPages === 0}
        role="button"
        tabIndex={0}
      >
        {Table('previous')}
      </YButtonCustom>

      <ContainerNumber>
        {renderPageNumber(1)}

        {currentPage > 3 && totalPages > 4 && <span>...</span>}

        {/* Exibe a página atual, a anterior e a próxima */}
        {[...Array(totalPages)].map((_, index) => {
          const pageNumber = index + 1
          if (
            pageNumber > 1 &&
            pageNumber < totalPages &&
            (pageNumber === currentPage ||
              pageNumber === currentPage - 1 ||
              pageNumber === currentPage + 1)
          ) {
            return renderPageNumber(pageNumber)
          }
          return null
        })}

        {currentPage < totalPages - 2 && totalPages > 4 && <span>...</span>}

        {totalPages > 1 && renderPageNumber(totalPages)}
      </ContainerNumber>

      <YButtonCustom
        onClick={handleNextPage}
        iconPosition="right"
        variation="text"
        icon={<YIcon name="arrowRight" />}
        size="fitContent"
        aria-label={Table('nextPageAriaLabel', { page: currentPage + 1 })}
        title={Table('nextPageAriaLabel', { page: currentPage + 1 })}
        disabled={currentPage === totalPages || totalPages === 0}
        role="button"
        tabIndex={0}
      >
        {Table('next')}
      </YButtonCustom>
    </ContainerPagination>
  )
}

export default Pagination
