import React from 'react'

/**
 * Bloqueia/desbloqueia o scroll do corpo da página
 */
const useBodyScrollLock = (isOpen: boolean) => {
  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }

    return () => {
      document.body.style.overflow = ''
    }
  }, [isOpen])
}

export default useBodyScrollLock
