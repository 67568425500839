import React from 'react'
import { YButton } from '@hand-talk/yotta-components'
import { useTranslation } from 'react-i18next'

/** Utils */
import UrlLink from './UrlLink'

/** Styles */
import {
  Tbody,
  TdLink,
  Th,
  Td,
  Thead,
  StyledTable,
  ExpansionButton,
  ContainerTable,
} from './styles'

/** Interfaces */
import {
  IDomain,
  IFilterProperties,
  ITableItem,
} from '../../services/interfaces'
import { formatDomain } from '../../services/helper'

/** Tokens */
import { neutral } from '../../assets/tokens/colors'
import { EmptyContent } from './EmptyContent'

/** Components */
import Expansion from './Expansion'
import FullScreen from '../../assets/svg/FullScreen'
import LoadingAnimated from '../LoadingAnimated'

interface ITableHeader {
  id: string
  text: () => string
  className?: string
}

interface ITable {
  data: ITableItem[]
  headers: ITableHeader[]
  domain?: IDomain
  url?: boolean

  expansion?: boolean
  dataExpansion?: any
  isModalOpen?: boolean
  dataExpansionState?: string
  currentPage?: number

  toggleModal?: () => void
  searchNewPageList?: (filterProperties: IFilterProperties) => void
}

const Table: React.FC<ITable> = ({
  data,
  domain,
  headers,
  url,
  expansion,
  dataExpansionState,
  dataExpansion,
  currentPage,
  isModalOpen,
  toggleModal,
  searchNewPageList,
}) => {
  const { t: TableTranslation } = useTranslation('Table')

  const formattedDomain = formatDomain(domain)

  const handlePaginatedExpansion = () => {
    searchNewPageList!({ limit: 10, currentPage: 1 })
    toggleModal!()
  }

  const renderTableRows = (
    tableData: ITableItem[],
    currentPage?: number,
    limit?: number
  ) =>
    tableData.map((item, index) => (
      <tr
        key={item.source + index}
        style={
          index % 2 === 0
            ? { background: neutral.color.high.lightest }
            : { background: neutral.color.high.main }
        }
      >
        <Td headers={headers[1].id}>
          {currentPage && limit
            ? limit * (currentPage - 1) + index + 1
            : index + 1}
          º
        </Td>
        {url ? (
          <TdLink headers={headers[2].id}>
            <UrlLink domain={formattedDomain} route={item.source} />
          </TdLink>
        ) : (
          <Td headers={headers[2].id}>{item.source}</Td>
        )}
        <Td headers={headers[3].id}>{item.value}</Td>
      </tr>
    ))

  const renderTable = (
    tableData: ITableItem[] = [],
    modalExpansion?: boolean,
    dataExpansionState?: string
  ) => (
    <ContainerTable>
      <Thead>
        <tr>
          {headers.map((header) => (
            <Th
              key={header.id}
              id={header.id}
              scope="col"
              className={header.className}
            >
              {header.text()}
            </Th>
          ))}
        </tr>
      </Thead>
      <Tbody>
        {dataExpansionState === 'loading' ? (
          <tr>
            <td></td>
            <Td>
              <LoadingAnimated isModal />
            </Td>
            <td></td>
          </tr>
        ) : tableData.length > 0 ? (
          renderTableRows(tableData, modalExpansion ? currentPage || 1 : 1, 10)
        ) : (
          <tr>
            <td></td>
            <Td>
              <EmptyContent modalExpansion={modalExpansion} />
            </Td>
            <td></td>
          </tr>
        )}
      </Tbody>
    </ContainerTable>
  )

  return (
    <>
      <StyledTable>{renderTable(data)}</StyledTable>

      {expansion && data.length > 0 && (
        <ExpansionButton>
          <YButton
            onClick={handlePaginatedExpansion!}
            iconPosition="right"
            variation="text"
            icon={<FullScreen />}
            size="fitContent"
            title={TableTranslation('fullViewButtonAriaLabel')}
            aria-label={TableTranslation('fullViewButtonAriaLabel')}
          >
            {TableTranslation('fullView')}
          </YButton>
        </ExpansionButton>
      )}
      {dataExpansion && (
        <Expansion
          renderTable={(tableData) =>
            renderTable(tableData, true, dataExpansionState!)
          }
          isModalOpen={isModalOpen!}
          toggleModal={toggleModal!}
          dataExpansion={dataExpansion!}
          dataExpansionState={dataExpansionState!}
          currentPage={currentPage!}
          searchNewPageList={searchNewPageList!}
        />
      )}
    </>
  )
}

export default Table
