// Libs
import { YIcon } from '@hand-talk/yotta-components'
import { useTranslation } from 'react-i18next'

// Services
import { ITableItem } from '../../services/interfaces'
import { extractData } from '../../services/helper'

// Components
import CardHelper from '../CardHelper'
import Table from '../Table'

interface IUserChart {
  topDevices: ITableItem[]
  isAverage: boolean
}

const TopDevices: React.FC<IUserChart> = ({ topDevices, isAverage }) => {
  const { t: translationUserTable } = useTranslation('UserTable')

  const dataTopDevices = extractData(topDevices, 'source', 'value')

  return (
    <CardHelper
      className="PrintOnlyMedium"
      marginType={'none'}
      title={translationUserTable('usersByDeviceTitle')}
      icon={<YIcon name="laptop" />}
      btnHelpContent={translationUserTable('devicesContent')}
    >
      <Table
        data={dataTopDevices}
        headers={[
          { id: 'DevicesEmpty', text: () => '', className: 'mobile' },
          {
            id: 'DevicesPosition',
            text: () => translationUserTable('position'),
            className: 'desktop',
          },
          { id: 'Devices', text: () => translationUserTable('devices') },
          {
            id: 'DevicesUsers',
            text: () =>
              translationUserTable(isAverage ? 'averageUsers' : 'users'),
          },
        ]}
      />
    </CardHelper>
  )
}

export default TopDevices
