/** Library */
import React from 'react'
import i18n from '../../../i18n'

/** Services */
import { formatDashboardValue, formatDateRange } from '../../../services/helper'

/** Interfaces */
import { IReportSchema } from '../../../services/interfaces'

/** Components */
import TopContainer from '../../../components/TopContainer'
import Overview from '../../../components/Overview'
import Chart from '../../../components/Chart'
import Footer from '../../../components/Footer'
import UserTable from '../../../components/UserTable'
import TopAddons from '../../../components/TopAddons'
import Achievement from '../../../components/Achievement'

type TReport = {
  report: IReportSchema
  filter: JSX.Element
  isAverage: boolean
  handleExportCSV?: () => void
  handleExportPDF: () => void
}

/**
 * Renderiza o conteúdo do relatório único.
 */
const Report: React.FC<TReport> = ({
  report,
  isAverage,
  filter,
  handleExportCSV,
  handleExportPDF,
}) => {
  const {
    period,
    translations,
    translations: { translatedWords },
    insight: { topPages, topStates, topDevices, topAddons, users, addons },
    info: { domain, filterLanguages, token },
    achievements: { translatedWordsAccumulated },
  } = report

  /** Manipulação do período */
  const periodFormattedFull = formatDateRange(period, i18n.language)

  const dataTopTopAddons = topAddons && topAddons.length > 0 ? topAddons : []
  const dataTopDevices = topDevices && topDevices.length > 0 ? topDevices : []
  const dataTopStates = topStates && topStates.length > 0 ? topStates : []

  const translationsAccumulatedFormatted = formatDashboardValue(
    translatedWordsAccumulated
  ).formatted

  const data = {
    translations: formatDashboardValue(translatedWords).formatted,
    users: formatDashboardValue(users).formatted,
    addons: formatDashboardValue(addons && addons > 0 ? addons : 0).formatted,
  }

  return (
    <>
      <TopContainer
        onClickExportCsv={handleExportCSV}
        onClickExportPdf={handleExportPDF}
        filter={filter}
        domain={domain}
        translationsAccumulatedFormatted={translationsAccumulatedFormatted}
        addons={data.addons}
      />
      <Overview
        isAverageReport={isAverage}
        data={data}
        period={periodFormattedFull}
      />
      <Chart translations={translations} filterLanguages={filterLanguages} />
      <TopAddons data={dataTopTopAddons} period={periodFormattedFull} />
      <UserTable
        token={token}
        isAverage={isAverage}
        topStates={dataTopStates}
        topDevices={dataTopDevices}
        topPages={topPages}
        domain={domain}
        periodFormattedFull={periodFormattedFull}
        period={period}
      />
      <Achievement translationsAccumulated={translatedWordsAccumulated} />
      <Footer />
    </>
  )
}

export default Report
