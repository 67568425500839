/** Interfaces */
import { ICardHelper } from '../../services/interfaces'

/** Styles */
import { Section, TopCard, StyledIcon } from './styles'

/** Components */
import Title from '../Title'
import ButtonHelperContainer from '../ButtonHelperContainer'

const CardHelper: React.FC<ICardHelper> = ({
  children,
  title,
  icon,
  buttonHelp,
  noTopCardMargin,
  marginType,
  className,
  btnHelpContent = '',
  btnHelpClassName,
  btnHelpBottomChildren,
  btnHelpTopChildren,
  ...props
}) => {
  return (
    <Section className={className} marginType={marginType}>
      <TopCard noTopCardMargin={noTopCardMargin}>
        <div className="AlignCenter">
          <StyledIcon>{icon}</StyledIcon>
          <Title tag={'h2'} className={'TitleWithIcon'} children={title} />
        </div>
        {buttonHelp ? (
          buttonHelp
        ) : (
          <ButtonHelperContainer
            {...{
              btnHelpContent,
              btnHelpClassName,
              btnHelpBottomChildren,
              btnHelpTopChildren,
              ...props,
            }}
          />
        )}
      </TopCard>
      {children}
    </Section>
  )
}

export default CardHelper
