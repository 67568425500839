// Libs
import { useEffect, useState, useMemo } from 'react'
import {
  YIcon,
  YIconButton,
  YMenu,
  YMenuItem,
  YInputSelect,
  YLink,
} from '@hand-talk/yotta-components'
import i18n, { setLanguage } from '../../i18n'
import { useTranslation } from 'react-i18next'

// Types
import { AverageReport, ICsvLabels, Report } from '../../services/interfaces'
import { IFilterData } from '../../components/Filter/types'

// Services
import * as Analytics from '../../services/analytics'
import { getLastMonthPeriod } from '../../services/utils'
import { formatPeriodWithTemplate } from '../../services/helper'

// Components
import LoadingWrapper from '../LoadingWrapper'
import ReportComponent from './Report'
import AverageReportComponent from './AverageReport'

// Hooks
import useReport from '../../hooks/useReport'
import usePreferencies from '../../hooks/usePreferencies'
import Filter from '../../components/Filter'
import Header from '../../components/Header'
import useAuth from '../../hooks/useAuth'
import { exportCsv } from '../../services/exportCsv'
import Layout from '../../components/Layout'
import { formatDashboardValue, printPDF } from '../../services/helper'
import LanguageSwitcher from '../../components/LanguageSwitcher'
import useUserInfo from '../../hooks/useUserInfo'
import Loading from '../../assets/svg/Loading'
import Menu from '../../components/Menu'
import useAverageReport from '../../hooks/useAverageReport'
import { YInputSelectedStyled } from '../../components/YInputSelect'
import SmallDataCard from '../../components/SmallDataCard'

const ClientDashboard = () => {
  const { language, accessKey } = usePreferencies()
  const { signOut, authState } = useAuth()
  const { fetchUserInfo, userInfoState } = useUserInfo()
  const [showAverageReport, setShowAverageReport] = useState(false)
  const { report, filterOptions, isLoading, getReport, filterReport } =
    useReport()
  const {
    averageReport,
    filterAverageReport,
    isLoading: isLoadingAverageReport,
  } = useAverageReport()
  const [cachedFilterData, setCachedFilterData] = useState<IFilterData | null>(
    null
  )

  const translatedWordsAccumulated =
    report?.achievements.translatedWordsAccumulated ?? 0
  const translationsAccumulatedFormatted = formatDashboardValue(
    translatedWordsAccumulated
  ).formatted

  useEffect(() => {
    if (authState.type === 'success' && userInfoState.type === 'none') {
      fetchUserInfo()
    }
  }, [authState, fetchUserInfo, userInfoState])

  useEffect(() => {
    if (!report) return
    Analytics.accessCounterEvent(report.info.domain)
  }, [report])

  useEffect(() => {
    if (!accessKey || !language) return

    getReport(accessKey, language)
    filterAverageReport(accessKey, getLastMonthPeriod(), language)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessKey, language, getReport, filterAverageReport])

  const handleOnFilter = (data: IFilterData) => {
    filterReport(accessKey, data.period, data.language)
    filterAverageReport(accessKey, data.period, data.language)
    setCachedFilterData(data)
    setLanguage(data.language)
    setShowAverageReport(data.view === 'monthlyAverage')
  }

  const initialData = useMemo(() => {
    return {
      period: report?.period || getLastMonthPeriod(),
      language,
      view: 'standard',
    } as IFilterData
  }, [language, report])

  const filter = (tagReport: string, periodReport: string) => {
    return (
      <Filter
        onFilter={handleOnFilter}
        initialData={cachedFilterData || initialData}
        options={filterOptions}
        periodReport={periodReport}
        tagReport={tagReport}
      />
    )
  }

  const { t: translateReport } = useTranslation('Report')

  const csvLabels: ICsvLabels = {
    csvTitle: translateReport('csvTitle'),
    csvDiary: translateReport('csvDiary'),
    csvCumulative: translateReport('csvCumulative'),
    csvTopPages: translateReport('csvTopPages'),
    csvTopStates: translateReport('csvTopStates'),
    csvTopDevices: translateReport('csvTopDevices'),
    csvTopAddons: translateReport('csvTopAddons'),
  }

  const handleExportCSV = () => {
    if (!report) return

    exportCsv(report, csvLabels)
  }

  const handleExportPDF = () => {
    printPDF()
  }

  const handleGoToDomainList = async () => {
    await Analytics.clickBackToDomainListsEvent()
    window.location.href = '/domain-list'
  }

  const handleLogout = async () => {
    await signOut()
    await Analytics.logoutEvent()
    window.location.href = '/'
  }

  const handleChangeReport = async (option: {
    name: string
    value: string
  }) => {
    await Analytics.clickDomainInDropdownsEvent(option.name)
    window.location.href = `/${option.value}`
  }

  const renderMenu = ({ options, onSelect, selectedOption, onClose }: any) => (
    <YMenu
      fluid={true}
      direction="column"
      position={{ top: '53px' }}
      zIndex={2}
      onClose={onClose}
    >
      {options.map((option: any) => (
        <YMenuItem
          key={option.value}
          onClick={() => onSelect(option)}
          selected={option.value === selectedOption?.value}
        >
          {option.name}
        </YMenuItem>
      ))}
    </YMenu>
  )

  const header = (
    <>
      <Header.Navbar>
        <LanguageSwitcher />
        {authState.type === 'success' && (
          <Menu.Profile onClickLogout={handleLogout} />
        )}
      </Header.Navbar>
      <Header.Menu>
        <Header.Containers.Domain>
          <Header.Containers.Upper>
            <Layout.Flex gap={8}>
              {authState.type === 'success' && (
                <YIconButton
                  aria-label={translateReport('buttonListDomain')}
                  title={translateReport('buttonListDomain')}
                  onClick={handleGoToDomainList}
                  children={<YIcon name="arrowLeft" />}
                />
              )}
              <Header.Title />
            </Layout.Flex>
            {userInfoState.type === 'success' && (
              <YInputSelectedStyled>
                <YInputSelect
                  onSelectOption={handleChangeReport}
                  placeholder={report?.info.domain}
                  value={report?.accessKey?.value}
                  id="domainSelect"
                  renderMenu={renderMenu}
                  options={userInfoState.userInfo.reports.map(
                    (partialReport) => ({
                      name: partialReport.info.domain,
                      value: partialReport.accessKey.value,
                    })
                  )}
                />
              </YInputSelectedStyled>
            )}
            {(authState.type === 'loading' ||
              userInfoState.type === 'loading') && <Loading />}
            {(authState.type === 'error' || authState.type === 'none') && (
              <YLink
                icon={<YIcon name="externalLink" />}
                iconPosition="right"
                href={`https://${report?.info.domain}`}
                target="_blank"
              >
                {report?.info.domain}
              </YLink>
            )}
          </Header.Containers.Upper>
          <Header.Containers.Lower>
            <SmallDataCard
              text={translateReport('translatedWords')}
              value={translationsAccumulatedFormatted}
            />
          </Header.Containers.Lower>
        </Header.Containers.Domain>
      </Header.Menu>
    </>
  )

  const createReport = (report: Report) => {
    const { period } = report
    const periodString = formatPeriodWithTemplate(period, i18n.language)
    return (
      <>
        {header}
        <ReportComponent
          isAverage={showAverageReport}
          report={report}
          filter={filter('standard', periodString)}
          handleExportCSV={handleExportCSV}
          handleExportPDF={handleExportPDF}
        />
      </>
    )
  }

  const createAverageReport = (averageReport: AverageReport) => {
    const { period } = averageReport
    const periodString = formatPeriodWithTemplate(period, i18n.language)
    return (
      <>
        {header}
        <AverageReportComponent
          isAverage={showAverageReport}
          averageReport={averageReport}
          filter={filter('monthlyAverage', periodString)}
          handleExportPDF={handleExportPDF}
        />
      </>
    )
  }

  return (
    <LoadingWrapper loading={isLoading || isLoadingAverageReport}>
      {!isLoading && !showAverageReport && report && createReport(report)}
      {!isLoadingAverageReport &&
        showAverageReport &&
        averageReport &&
        createAverageReport(averageReport)}
    </LoadingWrapper>
  )
}

export default ClientDashboard
