/** Libs */
import React from 'react'
import { YContentTab, YIcon, YTabs } from '@hand-talk/yotta-components'
import { useTranslation } from 'react-i18next'

/** Utils */
import GetDataDaily from './getDataDaily'
import GetDataAcumulative from './getDataAcumulative'
import GetMonthlyTranslations from './getDataMonthly'
import { Acumulative, Daily, Monthly } from './Graphics'

/** Components */
import CardHelper from '../CardHelper'
import CustomContainer from '../CustomContainer'
import Title from '../Title'
import CustomSelect from '../CustomSelect'

/** Services */
import { IChart, Language } from '../../services/interfaces'
import { getStoredItem, setStoredItem } from '../../services/localStorage'
import { isMobile } from '../../services/helper'

const Chart: React.FC<IChart> = ({ translations, filterLanguages }) => {
  const { t: translationChart, i18n } = useTranslation('Chart')

  /**
   * Carrega a língua do localStorage ou usa a primeira do filtro se não houver nenhuma armazenada
   */
  const [selectedLanguage, setSelectedLanguage] = React.useState<Language>(
    getStoredItem<Language>('selectedLanguage') || filterLanguages[0]
  )

  React.useEffect(() => {
    /**
     * Atualiza a língua armazenada em selectedLanguage.
     */
    setStoredItem('selectedLanguage', selectedLanguage)
  }, [selectedLanguage])

  const chartDataDaily = GetDataDaily(
    translations,
    selectedLanguage,
    i18n.language
  )
  const chartDataAcumulative = GetDataAcumulative(
    translations,
    selectedLanguage,
    i18n.language
  )
  const chartDataMonthly = GetMonthlyTranslations(
    translations,
    selectedLanguage,
    i18n.language
  )

  return (
    <CustomContainer className="PrintOnly">
      <Title
        tag={'h2'}
        className={'TitleAll'}
        children={translationChart('title')}
      />
      <CardHelper
        title={translationChart('translatedWordsTitle')}
        icon={<YIcon name="signLanguage" />}
        noTopCardMargin={isMobile()}
        btnHelpContent={translationChart('translatedWordsContent')}
        btnHelpTopChildren={
          !isMobile() ? (
            <CustomSelect
              value={selectedLanguage}
              options={filterLanguages}
              onChange={(newValue) => setSelectedLanguage(newValue as Language)}
            />
          ) : (
            <></>
          )
        }
      >
        {isMobile() && (
          <CustomSelect
            value={selectedLanguage}
            onChange={(newValue) => setSelectedLanguage(newValue as Language)}
            options={filterLanguages}
          />
        )}
        <YTabs>
          <YContentTab
            context="dark"
            chooseTokenColor="neutral"
            showIcon={false}
            title={translationChart('accumulativeTabTitle')}
          >
            <Acumulative
              translationsData={chartDataAcumulative}
              translation={translationChart}
            />
          </YContentTab>
          <YContentTab
            context="dark"
            chooseTokenColor="neutral"
            showIcon={false}
            title={translationChart('dailyTabTitle')}
          >
            <Daily
              translationsData={chartDataDaily}
              translation={translationChart}
            />
          </YContentTab>
          <YContentTab
            context="dark"
            chooseTokenColor="neutral"
            showIcon={false}
            title={translationChart('monthlyTabTitle')}
          >
            <Monthly
              translationsData={chartDataMonthly}
              translation={translationChart}
            />
          </YContentTab>
        </YTabs>
      </CardHelper>
    </CustomContainer>
  )
}

export default Chart
