import React from 'react'
import { useTranslation } from 'react-i18next'
import { YButton, YIcon, YInput } from '@hand-talk/yotta-components'

/** Components */
import Pagination from './Pagination'
import YModal from '../YModal'

/** Interfaces */
import { IFilterProperties, ITableItem } from '../../services/interfaces'

/** Styles */
import {
  ContainerForm,
  ContainerInput,
  ContentExpansion,
  StyledTable,
} from './styles'

interface IExpansion {
  isModalOpen: boolean
  dataExpansionState?: string
  renderTable: (
    tableData: ITableItem[],
    modalExpansion: boolean,
    state?: string
  ) => React.ReactNode
  toggleModal: () => void
  dataExpansion: any
  currentPage: number
  searchNewPageList: (filterProperties: IFilterProperties) => void
}

const Expansion: React.FC<IExpansion> = ({
  isModalOpen,
  dataExpansionState,
  renderTable,
  toggleModal,
  dataExpansion,
  currentPage,
  searchNewPageList,
}) => {
  const { t: Table } = useTranslation('Table')
  const [filterContent, setFilterContent] = React.useState('')

  const applyFilter = () => {
    searchNewPageList({ limit: 10, filterContent })
  }

  React.useEffect(() => {
    if (isModalOpen) {
      setFilterContent('')
    }

    return () => setFilterContent('')
  }, [isModalOpen])

  return (
    <YModal
      isOpen={isModalOpen}
      onClose={toggleModal}
      title={Table('fullViewRegions')}
      closeButtonAriaLabel={Table('closeModal')}
    >
      <ContentExpansion>
        <ContainerForm>
          <YInput
            inputProps={{
              value: filterContent,
              placeholder: Table('filterPlaceholder'),
              'aria-label': Table('filterInputAriaLabel'),
              title: Table('filterInputAriaLabel'),
              onChange: (e: {
                target: { value: React.SetStateAction<string> }
              }) => setFilterContent(e.target.value),
              onKeyDown: (e: { key: string }) => {
                if (e.key === 'Enter') {
                  applyFilter()
                }
              },
            }}
            leftAdornment={
              <ContainerInput
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  height: 44,
                  justifyContent: 'center',
                  width: 44,
                }}
              >
                <YIcon name="search" />
              </ContainerInput>
            }
          />
          <YButton
            onClick={applyFilter}
            aria-label={Table('confirmFilterAriaLabel')}
            title={Table('confirmFilterAriaLabel')}
          >
            {Table('confirmFilter')}
          </YButton>
        </ContainerForm>

        <StyledTable>
          {renderTable(dataExpansion.data, true, dataExpansionState)}
        </StyledTable>

        {dataExpansion.totalPages > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPages={dataExpansion.totalPages}
            onPageChange={(page) =>
              searchNewPageList!({
                limit: 10,
                currentPage: page,
                filterContent,
              })
            }
          />
        )}
      </ContentExpansion>
    </YModal>
  )
}

export default Expansion
