/** Libs */
import React from 'react'
import { YIcon } from '@hand-talk/yotta-components'
import { useTranslation } from 'react-i18next'

/** Services */
import { IPeriod, ITableItem } from '../../services/interfaces'
import { extractData } from '../../services/helper'

/** Components */
import CardHelper from '../CardHelper'
import Table from '../Table'

/** Hooks */
import useRegionExpansion from '../../hooks/useRegionExpansion'

interface ITopStates {
  topStates: ITableItem[]
  isAverage: boolean
  token: string
  period: IPeriod
}

const TopStates: React.FC<ITopStates> = ({
  topStates,
  isAverage,
  token,
  period,
}) => {
  const { t: translationUserTable } = useTranslation('UserTable')

  const {
    isModalOpenRegion,
    currentPageRegion,
    expansionRegion,
    dataExpansionStateRegion,
    toggleModalRegion,
    searchNewRegionList,
  } = useRegionExpansion({ token, period })

  const dataStates =
    topStates && topStates.length > 0
      ? extractData(topStates, 'source', 'value')
      : []

  return (
    <CardHelper
      marginType={'none'}
      title={translationUserTable('usersByLocationTitle')}
      icon={<YIcon name="place" />}
      btnHelpContent={translationUserTable('stateContent')}
    >
      <Table
        data={dataStates}
        headers={[
          { id: 'headerEmpty', text: () => '', className: 'mobile' },
          {
            id: 'headerPosition',
            text: () => translationUserTable('position'),
            className: 'desktop',
          },
          { id: 'headerState', text: () => translationUserTable('location') },
          {
            id: 'headerUsers',
            text: () =>
              translationUserTable(isAverage ? 'averageUsers' : 'users'),
          },
        ]}
        isModalOpen={isModalOpenRegion}
        expansion={true}
        dataExpansion={expansionRegion}
        dataExpansionState={dataExpansionStateRegion}
        currentPage={currentPageRegion}
        toggleModal={toggleModalRegion}
        searchNewPageList={searchNewRegionList}
      />
    </CardHelper>
  )
}

export default TopStates
